import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Container from '../components/Container';
import Balloon from '../illustrations/Balloon';

import s from './notFoundPage.module.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Siden finnes ikke" />
    <div className={s.balloonContainer}>
      <Balloon />
    </div>
    <main className={s.content}>
      <Container>
        <h1>404</h1>
        <p>Siden du ser etter finnes ikke</p>
      </Container>
    </main>
  </Layout>
);

export default NotFoundPage;
